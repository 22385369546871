import React, {useEffect, useState} from "react";

const Badges = () => {
    const [htmlCode, setHtmlCode] = useState("")
    const [width, setWidth] = useState(240)
    const [restaurantId, setRestaurantId] = useState("")
    const [selectedBadge, setSelectedBadge] = useState("book-on-reetab-dark")

    const generateHtmlCode = () => {
        let code = ""
        if (selectedBadge !== "") {
            code = `<a href="https://reetab.com/book/${restaurantId}" target="_blank" rel="noopener"><img style="width: ${width}px; height: auto" src="https://business.reetab.com/badges/${selectedBadge}.png" alt="Book a table" width="${width}"/></a>`
        }
        setHtmlCode(code)
    }

    useEffect(() => {
        generateHtmlCode()
    }, [selectedBadge, width, restaurantId])

    const widthPx = `${width}px`

    return <div className="Badges">
        <section id="privacy" className="terms-section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <h2>Add badge to your website</h2>
                        <p>Select your preferred <b>badge</b> and <b>size</b> to generate html code. If you enter your <b>restaurant
                            ID</b> a click on
                            the
                            badge will lead directly to your restaurant booking page in the app.</p>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-lg-6">
                        <h4 className={"mb-3"}>Select badge</h4>
                        <button className={"btn badge-btn"} onClick={() => setSelectedBadge("book-on-reetab-dark")}>
                            <img
                                className={selectedBadge === "book-on-reetab-dark" ? "book-badge book-badge-selected" : "book-badge"}
                                src="/badges/book-on-reetab-dark.png" alt="dark" width="564"
                                height="168"/>
                        </button>
                        <button className={"btn badge-btn"} onClick={() => setSelectedBadge("book-on-reetab-dark-red")}>
                            <img
                                className={selectedBadge === "book-on-reetab-dark-red" ? "book-badge book-badge-selected" : "book-badge"}
                                src="/badges/book-on-reetab-dark-red.png" alt="dark red"
                                width="564"
                                height="168"/>
                        </button>
                        <button className={"btn badge-btn"}
                                onClick={() => setSelectedBadge("book-on-reetab-dark-white-logo")}>
                            <img
                                className={selectedBadge === "book-on-reetab-dark-white-logo" ? "book-badge book-badge-selected" : "book-badge"}
                                src="/badges/book-on-reetab-dark-white-logo.png" alt="dark red"
                                width="564"
                                height="168"/>
                        </button>
                        <button className={"btn badge-btn"} onClick={() => setSelectedBadge("book-on-reetab-light")}>
                            <img
                                className={selectedBadge === "book-on-reetab-light" ? "book-badge book-badge-selected" : "book-badge"}
                                src="/badges/book-on-reetab-light.png" alt="dark red"
                                width="564"
                                height="168"/>
                        </button>
                        <div className={"mb-3"}>
                            <h5 className={"mb-3 mt-3"}>Select size</h5>
                            <button onClick={() => setWidth(160)}
                                    className={width === 160 ? "btn btn-md btn-dark mr-2" : "btn btn-md btn-light mr-2"}
                            >
                                Small
                            </button>
                            <button onClick={() => setWidth(200)}
                                    className={width === 200 ? "btn btn-md btn-dark mr-2" : "btn btn-md btn-light mr-2"}>Medium
                            </button>
                            <button onClick={() => setWidth(240)}
                                    className={width === 240 ? "btn btn-md btn-dark mr-2" : "btn btn-md btn-light mr-2"}>Large
                            </button>
                            <button onClick={() => setWidth(320)}
                                    className={width === 320 ? "btn btn-md btn-dark mr-2" : "btn btn-md btn-light mr-2"}>XLarge
                            </button>
                        </div>
                        <h5 className={"mb-3 mt-4"}>Enter your restaurant id</h5>
                        <div className={"col-6 mb-5"}>
                            <input className="form-control input-x" id="restaurantId"
                                   placeholder="Restaurant ID" value={restaurantId}
                                   onChange={e => setRestaurantId(e.target.value)}/>

                        </div>
                    </div>
                    <div className="col-lg-6">
                        <h4>HTML code</h4>


                        <p>Include this HTML code to add badge on your website</p>
                        <textarea required className="form-control input-x mb-20" id="message" rows={5}
                                  value={htmlCode}/>
                        <h4 className={"mb-2"}>Preview</h4>

                        <a href="https://reetab.com/book/10" target="_blank" rel="noopener">
                            <img style={{width: widthPx, height: "auto"}} src={`/badges/${selectedBadge}.png`}
                                 alt="dark red"
                                 width="564"
                                 height="168"/>
                        </a>
                    </div>
                </div>

            </div>
        </section>
    </div>
}

export default Badges
