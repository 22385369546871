import React, {useEffect, useState} from "react";
import {ScrollMenu} from "react-horizontal-scrolling-menu";
import {format} from 'date-fns';
import ReservationForm from "../components/ReservationForm";
import Logo from "../assets/img/logo/reetab-logo-black.png";
import {useLocation} from "react-router-dom";
import queryString from 'query-string'

const TimeOption =
    ({time, selectedTime, setSelectedTime}: any) => {
        return (<button onClick={() => setSelectedTime(time)}
                        className={selectedTime === time ? "btn btn-md btn-dark mr-2 mb-2 border-min booking-select-item" : "btn btn-md btn-light mr-2 mb-2 border-min booking-select-item"}>{time}
        </button>)
    }

const GuestOption =
    ({guests, selectedNumberOfGuests, setSelectedNumberOfGuests}: any) => {
        return (<button onClick={() => setSelectedNumberOfGuests(guests)}
                        className={selectedNumberOfGuests === guests ? "btn btn-md btn-dark mr-2 mb-2 border-min booking-select-item" : "btn btn-md btn-light mr-2 mb-2 border-min booking-select-item"}>{guests}
        </button>)
    }


const Day = ({date, selectedDate, setSelectedDate, closed, mainColor}: any) => {
    let topColor = mainColor;
    if (date === selectedDate) {
        topColor = "#212529";
    }
    if (closed) {
        topColor = "#dee1e5";
    }
    let fontColor = "#212529";
    if (closed) {
        fontColor = "#dee1e5";
    }

    const dateObj = Date.parse(date)
    const weekDay = format(dateObj, 'EEEE')
    const month = format(dateObj, 'MMMM')
    const day = format(dateObj, 'd')

    return (
        <button className={"btn btn-sm button-free"} onClick={() => setSelectedDate(date)}>
            <div style={{
                display: 'flex',
                flexDirection: "column",
                padding: 0,
                width: 78,
                marginTop: 2,
                marginBottom: 5,
                marginRight: 7,
                marginLeft: 2,
                borderRadius: 2,
                overflow: "hidden",
                boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.2)",
                background: "white"
            }}>
                <div style={{background: topColor, margin: -1}}>
                    <span style={{fontSize: 13, lineHeight: 1.8, color: "white"}}>{month}</span>
                </div>
                <span style={{fontSize: 24, paddingTop: 2, paddingBottom: 1, color: fontColor}}>{day}</span>
                <span style={{
                    fontSize: 13,
                    lineHeight: 1,
                    color: fontColor,
                    paddingBottom: 6
                }}>{closed ? 'closed' : weekDay}</span>
            </div>
        </button>)
}

const Book = () => {
    const [selectedTime, setSelectedTime] = useState("")
    const [selectedDate, setSelectedDate] = useState("")
    const [selectedNumberOfGuests, setSelectedNumberOfGuests] = useState("")

    const [isLoading, setIsLoading] = useState(false);


    const [isAvailable, setIsAvailable] = useState(false);
    const [gotResult, setGotResult] = useState(false);

    const [confirmReservationStep, setConfirmReservationStep] = useState(false);

    const startHour = 12
    const endHour = 20

    let timeOptions = []
    for (let hour = startHour; hour < endHour; hour++) {
        timeOptions.push(`${hour}:00`)
        timeOptions.push(`${hour}:30`)
    }

    let guestsOptions = Array(8).fill(null).map((_, i) => i + 1);

    let dateOptions = [
        {date: "2021-08-31", closed: false},
        {date: "2021-09-01", closed: false},
        {date: "2021-09-02", closed: false},
        {date: "2021-09-03", closed: false},
        {date: "2021-09-04", closed: false},
        {date: "2021-09-05", closed: true},
        {date: "2021-09-06", closed: false},
        {date: "2021-09-07", closed: false},
        {date: "2021-09-08", closed: false},
        {date: "2021-09-09", closed: false},
        {date: "2021-09-10", closed: false},
        {date: "2021-09-11", closed: false},
        {date: "2021-09-12", closed: true},
    ]

    useEffect(() => {
        setGotResult(false)
    }, [selectedTime, selectedDate, selectedNumberOfGuests])

    const checkAvailability = () => {
        // TODO get available tables
        setIsLoading(true)
        setTimeout(() => {
            setGotResult(true)
            setIsAvailable(true)
            setIsLoading(false)
        }, 2000)
    }

    const continueToBooking = () => {
        setConfirmReservationStep(true)
    }

    const goBack = () => {
        setConfirmReservationStep(false)
    }

    const {search} = useLocation()
    const values = queryString.parse(search)

    const mainColor = values.color ? `${values.color}` : 'rgba(255,80,71,1)';
    console.log(values)
    console.log(values.color)
    console.log(mainColor)

    return <div className={"shadow-sm"}
                style={{
                    backgroundColor: "white",
                    borderRadius: 6,
                    overflow: "hidden",
                    margin: 4,
                    paddingTop: 6,
                    paddingLeft: 16,
                    paddingRight: 16,
                    paddingBottom: 16
                }}>
        <div>
            <div style={{
                flexDirection: "row",
                display: 'flex',
                justifyContent: "space-between",
                alignItems: "center",
                paddingBottom: 20,
                paddingTop: 10,
            }}>
                <img src={Logo} style={{height: 24, width: "auto"}}/>
                <h6 style={{fontSize: 16, fontWeight: 600, color: "#323234"}}>Book a table</h6>
            </div>
            <div className="row">
                {!confirmReservationStep &&
                <div className="col-lg-12">
                    <div className={"mb-2"}>
                        <h6 className={"mb-2 widget-item-title"}>Date</h6>
                        {confirmReservationStep ?
                            <h6 className={""}><span style={{fontWeight: 400}}>Monday, 12. Sep 2021</span>
                            </h6>
                            :
                            <ScrollMenu>

                                {dateOptions.map((x) => {
                                    const {closed, date} = x;
                                    return (<Day date={date} closed={closed} selectedDate={selectedDate}
                                                 setSelectedDate={setSelectedDate} mainColor={mainColor}/>);
                                })
                                }
                            </ScrollMenu>
                        }
                    </div>
                    <div className={"mb-2"}>
                        <h6 className={"mb-2 widget-item-title"}>Time</h6>
                        {confirmReservationStep ?
                            <h6 className={""}><span style={{fontWeight: 400}}>12:00</span>
                            </h6>
                            :
                            <ScrollMenu>
                                {timeOptions.map((x) => {
                                    return (<TimeOption time={x} selectedTime={selectedTime}
                                                        setSelectedTime={setSelectedTime}/>);
                                })
                                }
                            </ScrollMenu>
                        }
                    </div>
                    <div className={"mb-2"}>
                        <h6 className={"mb-2 widget-item-title"}>Guests</h6>
                        {confirmReservationStep ?
                            <h6 className={""}><span style={{fontWeight: 400}}>2</span>
                            </h6>
                            :
                            <ScrollMenu>
                                {guestsOptions.map((x) => {
                                    return (<GuestOption guests={x} selectedNumberOfGuests={selectedNumberOfGuests}
                                                         setSelectedNumberOfGuests={setSelectedNumberOfGuests}/>);
                                })
                                }
                            </ScrollMenu>
                        }
                    </div>
                    {!gotResult &&
                    <button
                        onClick={() => checkAvailability()}
                        disabled={isLoading}
                        className={"btn btn-md btn-dark mt-3 border-min btn-block booking-button button-red"}
                        style={{background: mainColor, borderColor: mainColor}}
                    >
                        {!isLoading ? "Check availability" : "Checking availability..."}
                    </button>
                    }
                    {gotResult && !isAvailable &&
                    <p>Not available. Please select different time or date.</p>
                    }
                    {gotResult && isAvailable && !confirmReservationStep &&
                    <>
                        <p className={"mt-3 reservation-text"}>Table is available for booking!</p>
                        <button
                            onClick={() => continueToBooking()}
                            className={"btn btn-md btn-dark mt-3 border-min btn-block booking-button"}
                            // style={{background: mainColor, borderColor: mainColor}}
                        >
                            Continue to booking
                        </button>
                        {/*<a onClick={() => continueToBooking()} href={"#"}>Continue to booking</a>*/}
                    </>
                    }
                    {/*{gotResult && isAvailable && !confirmReservationStep &&*/}
                    {/*<button*/}
                    {/*    onClick={() => continueToBooking()}*/}
                    {/*    disabled={false}*/}
                    {/*    className={"btn btn-md btn-dark mt-2 mb-2 border-min p-1 pl-3 pr-3 booking-button"}*/}
                    {/*>*/}
                    {/*    Continue to booking*/}
                    {/*</button>*/}
                    {/*}*/}
                </div>
                }
                {confirmReservationStep &&
                <div className="col-lg-12">
                    {/*<h4>Confirm reservation</h4>*/}
                    <p className={"mb-2 reservation-text mt-0"}>Booking for <b>{selectedNumberOfGuests} guests</b> on <b>{format(Date.parse(selectedDate), "d. MMMM yyyy")}</b> at <b>{selectedTime}</b></p>
                    <ReservationForm/>
                    <button className={"btn btn-sm btn-block mt-2"} onClick={() => goBack()}>Edit reservation</button>
                </div>
                }
            </div>

        </div>
    </div>
}

export default Book
