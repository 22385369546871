import React, {useRef, useState} from "react";
import Logo from "../assets/img/logo/reetab-business-logo-black.png";
import {HashLink} from "react-router-hash-link";
import {Urls} from "../constants/urls";
import {Trans, useTranslation} from "react-i18next";

const NavbarX = () => {
    const [navbarSticky, setNavbarSticky] = useState(false);
    const [navbarTogglerShow, setNavbarTogglerShow] = useState(false);
    const navbar = useRef(null);
    const navbarToggler = useRef(null);
    const {t} = useTranslation()

    const changeBackground = () => {
        if (navbar !== null && navbar.current !== null) {
            // @ts-ignore
            const sticky = navbar.current.offsetTop;
            if (window.scrollY > sticky) {
                setNavbarSticky(true);
            } else {
                setNavbarSticky(false);
            }
        }
    }

    const switchToggler = () => {
        setNavbarTogglerShow(prevState => !prevState)
    }

    window.addEventListener('scroll', changeBackground);

    return (
        <>
            <div className={navbarSticky ? "navbar-area sticky" : "navbar-area"} ref={navbar}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <nav className="navbar navbar-expand-lg">
                                <HashLink to={'/#'} className={"navbar-brand"}><img src={Logo} alt="Logo"/></HashLink>
                                <button ref={navbarToggler} onClick={switchToggler}
                                        className={navbarTogglerShow ? "navbar-toggler active" : "navbar-toggler"}
                                        type="button" data-bs-toggle="collapse"
                                        data-bs-target="#navbarSupportedContent" aria-controls="navbarTogglerDemo01"
                                        aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="toggler-icon"/>
                                    <span className="toggler-icon"/>
                                    <span className="toggler-icon"/>
                                </button>

                                <div className="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
                                    <ul id="nav" className="navbar-nav ml-auto aaa">
                                        <li className="nav-item">
                                            <HashLink to={'/#why'}><Trans t={t} i18nKey="menu.whyReetab"/></HashLink>
                                        </li>
                                        <li className="nav-item">
                                            <HashLink to={'/#features'}><Trans t={t}
                                                                               i18nKey="menu.features"/></HashLink>
                                        </li>
                                        <li className="nav-item">
                                            <HashLink to={'/#pricing'}><Trans t={t} i18nKey="menu.pricing"/></HashLink>
                                        </li>
                                        <li className="nav-item">
                                            <a className="" href={Urls.login} target="_blank"><Trans t={t} i18nKey="menu.login"/></a>
                                        </li>


                                        <li className="nav-item-button">
                                            <a href={Urls.signup} target="_blank"
                                               data-wow-delay=".3s" className="sign-up-button ">
                                                <Trans t={t} i18nKey="menu.signUp"/>
                                            </a>
                                        </li>

                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NavbarX;
