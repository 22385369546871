import React, {useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import {Urls} from "../constants/urls";

const ReservationForm = () => {
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [restaurantName, setRestaurantName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [message, setMessage] = useState("")

    const [isPending, setIsPending] = useState(false)
    const [isDone, setIsDone] = useState(false)
    const [isError, setIsError] = useState(false)

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const formData = {firstName, lastName, restaurantName, email, message}

        setIsPending(true)

        fetch(Urls.contactAPI, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        }).then(res => {
            if (!res.ok) {
                setIsError(true)
            }
            setIsDone(true)
        })
        console.log(formData)
    }

    const {t, i18n} = useTranslation()


    return <form onSubmit={handleSubmit}>

        <div className="form-group mb-1">
            <label htmlFor="name" className="mb-0"><Trans t={t} i18nKey="messageForm.firstName"/></label>
            <input disabled={isDone} required type="text" className="form-control input-x"
                   placeholder={i18n.t('messageForm.firstName')}
                   id="name" value={firstName} onChange={e => setFirstName(e.target.value)}/>
        </div>
        <div className="form-group mb-1">
            <label htmlFor="last-name" className="mb-0"><Trans t={t}
                                                               i18nKey="messageForm.lastName"/></label>
            <input disabled={isDone} required type="text" className="form-control input-x"
                   placeholder={i18n.t('messageForm.lastName')}
                   id="last-name" value={lastName} onChange={e => setLastName(e.target.value)}/>
        </div>
        <div className="form-group mb-1">
            <label htmlFor="email" className="mb-0"><Trans t={t} i18nKey="messageForm.email"/></label>
            <input disabled={isDone} required type="email" className="form-control input-x" id="email"
                   placeholder="email@example.com" value={email} onChange={e => setEmail(e.target.value)}/>
        </div>
        <div className="form-group mb-1">
            <label htmlFor="phone" className="mb-0"><Trans t={t} i18nKey="messageForm.phone"/></label>
            <input disabled={isDone} required type="phone" className="form-control input-x" id="phone"
                   placeholder="Phone number" value={phone} onChange={e => setPhone(e.target.value)}/>
        </div>
        <div className="form-group mb-3">
            <label htmlFor="message" className="mb-0"><Trans t={t} i18nKey="messageForm.message"/></label>
            <textarea disabled={isDone} required className="form-control input-x" id="message" rows={3}
                      placeholder={i18n.t('messageForm.message')}
                      value={message}
                      onChange={e => setMessage(e.target.value)}/>
        </div>

        <button type="submit" className="btn btn-md btn-dark border-min p-1 pl-3 pr-3 btn-block booking-button">Confirm
            Reservation
        </button>
    </form>
}

export default ReservationForm
