export const Urls = {
    login: "https://app.reetab.com/app/login",
    signup: "https://app.reetab.com/app/register",
    contactAPI: "https://reetab.com/api/landing_page/contact",
    appStoreReetabBusiness: "https://apps.apple.com/us/app/reetab-business/id1478790179",
    playStoreReetabBusiness: "https://play.google.com/store/apps/details?id=com.reetab.reetabBusiness",
}

export const DragDropDoneVideoSrc = "https://reetab.s3.eu-central-1.amazonaws.com/landing-page/videos/add-3-720-60.mp4"
export const DoneVideoSrc = "https://reetab.s3.eu-central-1.amazonaws.com/landing-page/videos/multitable-browser.mp4"
export const CreateCustomTableModelVideoSrc = "https://reetab.s3.eu-central-1.amazonaws.com/landing-page/videos/add-table-model-browser.mp4"
export const AddNewTableVideoSrc = "https://reetab.s3.eu-central-1.amazonaws.com/landing-page/videos/add-table-browser-720.mp4"
export const CreateCustomSpaceVideoSrc = "https://reetab.s3.eu-central-1.amazonaws.com/landing-page/videos/browser-spaces.mp4"
